$shuffll-red: #870300;
$shuffll-red-bright: #ffa197;
$shuffll-red-06: rgba(135, 3, 0, 0.6);
$semi-white: #cecece;
$light-gray: #9d9d9d;
$medium-gray: #303030;
$dark-gray: #1a1a1a;
$alpha-dark-gray-80: rgba(26, 26, 26, 0.8);
$alpha-dark-gray-60: rgba(26, 26, 26, 0.6);
$alpha-medium-gray-60: #55525299;
$alpha-medium-gray-60-calculated: #4a4848;
$primary: rgba(222, 74, 49, 1);
$primary-pink-light: rgb(240, 51, 125);
$primary-pink-light80: rgba(240, 51, 125, 0.8);
$shuffll-light-red: #bb0300;
$shuffll-purple: #9671ff;
$shuffll-purple-lt: #c0a9ff;
$shuffll-purple-llt: #d5c6ff;
$slightly-lighter: #ffffff03;
$lighter: #ffffff0f;
$really-lighter: rgba(255, 255, 255, 0.1);
$slightly-darker: rgba(0, 0, 0, 0.1);
$darker: rgba(0, 0, 0, 0.2);
$really-darker: rgba(0, 0, 0, 0.3);

$shuffll-purple-dark: #6e48c3;
$shuffll-purple-darker: #46296a;
$yellow: #ffa630;
$semi-gray: #e9e9e9;
$peach: rgb(222, 74, 49);
$cool-gradient: linear-gradient(315deg, $shuffll-purple-dark 0%, rgba(222, 74, 49, 1) 100%);
$cool-gradient-flipped: linear-gradient(132deg, #6e48c3 37%, rgb(222, 74, 49) 100%);
$sfl-gradient: linear-gradient(275deg, $shuffll-purple 0%, $shuffll-purple-lt 70%);
$main-app-bg: #141414;
$green: #0d9515;
$shuffll-green: #98ce00;
$dark-green: #15af00;

$card-surface-color: #181818;
$status-green: #52e05e;
$status-gray: #dbd4d3;
$status-orange: #ff9933;
$status-red: #e83151;
$status-offwhite: #ffdbb5;
$status-lavender: #e0bad7;
